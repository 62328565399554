.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.upper-box {
    flex: 1;
    overflow-y: auto;
    /*background-color: #99aae5;*/
    padding: 3% 5% 0 1% ;
    height: 100%;
}

.lower-box {
    height: 25%;
    background-color: #f8faff;
    padding: 2% 2% 0 2% ;
}