.truncate-overflow {
    --max-lines: 3;
    max-height: 10vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.history-box {
    padding:10px;
    background-color: #b0ceff;
    margin:10px;
    border-radius:5px;
}

